<template>
  <div>
    <MyHeader />
    <main id="content">
      <div class="container my-3 my-sm-5">
        <div class="row justify-content-center">
          <aside class="col-12 col-lg-8 col-xl-6"
                 v-if="booking && booking.property">
            <div class="bookpreview alt">
              <h1 class="text-primary">
                <small>Your reservation <strong v-if="booking && booking.confirmation_number">#{{ booking.confirmation_number }}</strong> {{ booking.status == 1 ? 'is confirmed' : 'is waiting for confirmation' }}</small>
              </h1>
              <hr>
              <img class="thumb"
                   v-if="booking.property.images.length > 0"
                   :src="booking.property.images[0].medium">
              <div class="info">
                <h2 v-if="booking.property.name">
                  {{ booking.property.name }}
                </h2>
                <h4 class="location"
                    v-if="booking.property.state && booking.property.state.name && booking.property.country && booking.property.country.name">
                  {{ booking.property.state.name }}, {{ booking.property.country.name }}
                </h4>
                <hr>
                <div class="d-flex flex-wrap">
                  <label class="calenBook mr-3 mr-sm-5">
                    <i class="icon-calendar" />
                    <div><small>Check In</small> {{ booking.check_in|beatyDate }}</div>
                    <i class="icon-arrow-right" />
                    <div><small>Check Out</small> {{ booking.check_out|beatyDate }}</div>
                  </label>
                  <label class="calenBook">
                    <i class="icon-users" />
                    <div><small>Travelers</small> {{ booking.travelers }}</div>
                  </label>
                </div>
                <!-- <hr> -->
                <div class="trip-detail-head mt-3"
                     v-if="booking && booking.concierge">
                  <div class="col-6 pb-2 pt-2">
                    <div class="concierge">
                      <div class="pic">
                        <img :src="booking && booking.concierge.avatar ? booking.concierge.avatar.path : '/img/user-thumb.jpg'">
                      </div>
                      <div class="name"
                           v-if="booking.concierge.name && booking.concierge.surname">
                        <h4>{{ booking.concierge.name }} {{ booking.concierge.surname }}</h4>
                        <p>Your Concierge</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p v-if="booking.status === 2">
                  Your reservation request has been sent !! In the next 24 hours one of our Experience Specialists will be contacting you to give you an answer.
                </p>
                <p v-else>
                  You can now start to manage your trip: Add Travelers, Add groceries for your travel.
                </p>
                <router-link v-if="booking.status === 2"
                             class="btn btn-primary"
                             :to="{ name: 'user-trips' }">
                  VIEW MY TRIPS
                </router-link>
                <router-link v-else
                             class="btn btn-primary"
                             :to="{ name: 'user-trip-overview', params: { id: this.$route.params.id } }">
                  MANAGE YOUR TRIP
                </router-link>
                <hr v-if="booking.status !== 2">
                <div class="row justify-content-between align-items-center"
                     v-if="booking.status !== 2">
                  <div class="col-12 col-md-6"
                       v-if="booking.property.address">
                    <address>
                      <strong>Address:</strong><br>
                      {{ booking.property.address }}
                    </address>
                  </div>
                  <div class="col-12 col-md-auto text-center"
                       v-if="booking.property && booking.property.lat && booking.property.lng">
                    <a :href="`http://maps.google.com/maps?q=${parseFloat(booking.property.lat)},${parseFloat(booking.property.lng)}&ll=${parseFloat(booking.property.lat)},${parseFloat(booking.property.lng)}&z=17`"
                       target="_blank"
                       class="btn btn-outline-primary btn-sm"><i class="icon-location" /> Get Directions</a>
                  </div>
                </div>
                <hr>
                <div class="learn-more"
                     v-if="booking.concierge && booking.concierge.phone">
                  <i class="icon-phone" />
                  <p>
                    Call <a :href="`tel:+${booking.concierge.phone }`">{{ booking.concierge.phone }}</a> to learn more.
                  </p>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </main>
    <MyFooter />
  </div>
</template>

<script>
export default {
  name: 'BookingConfirmation',
  data() {
    return {
      booking: {},
    };
  },
  methods: {
  },
  created() {
    this.$axios.get(`/v1/bookings/${this.$route.params.id}`).then((response) => {
      this.booking = response.data.data;
    });
  },
};
</script>

<style scoped>

</style>
